/* general styling */
:root {
    --smaller: .75;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  html, body {
    height: 100%;
    margin: 0;
  }
  
  
  .container {
    color: #333;
    margin: 0 auto;
    text-align: center;
  }
  
  h1 {
    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
  }
  
  li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 0 1em 1em 1em;
    text-transform: uppercase;
    color: white;
    margin-right: 20px;
  }
  
  li span {
    display: block;
    font-size: 7rem;
    font-weight: bold;
  }
  
  .emoji {
    display: none;
    padding: 1rem;
  }
  
  .emoji span {
    font-size: 4rem;
    padding: 0 .5rem;
  }

  #seconds{
    color: #FFCC00;
  }
  
  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(1.5rem * var(--smaller));
    }
    
    li {
      font-size: calc(1.125rem * var(--smaller));
      margin-right: 0px;
      margin: 15px 0;

    }
    
    li span {
      font-size: calc(3.375rem * var(--smaller));
    }
  }